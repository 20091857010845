import React, { useState } from 'react'
import Layout from '../components/Layout';
import PropTypes from 'prop-types';
import '../style/contact-us.sass';
import { graphql } from 'gatsby';
import { GatsbyImage , getImage } from "gatsby-plugin-image";
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css'
import L from 'leaflet';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import useWindowSize from '../hooks/useWindowSize';

delete L.Icon?.Default?.prototype._getIconUrl;

L.Icon?.Default?.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: ('/img/map-icon.png'),
  shadowUrl: markerShadow,
});

const SetMapView = ({ coordinates }) => {
  const map = useMap();
  map.setView(coordinates);
  return null;
};

const Section1 = ({ content1 }) => {
  const { width } = useWindowSize();
  // Initialize state with the first location's coordinates
  const [selectedCoordinates, setSelectedCoordinates] = useState([
    content1.array[0]?.coordinates?.latitude || 28.41479115207311,
    content1.array[0]?.coordinates?.longitude || 77.35657448632696
  ]);

  return (
    <div className={`section1-main-container ${width > 768 ? 'fit-page': ''}`}>
      <div className='section1-container'>
        <div className='section1-image'>
          <MapContainer style={{ height: '400px', width: '100%' }} center={selectedCoordinates} zoom={13} scrollWheelZoom={false}>
            <SetMapView coordinates={selectedCoordinates} />
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {content1.array.map((item, index) => {
              let coordinates = []
              if(item?.coordinates?.latitude) {
                coordinates.push(item.coordinates.latitude)
              }
              if(item?.coordinates?.longitude) {
                coordinates.push(item.coordinates.longitude)
              }
              return coordinates?.length && (
              <Marker key={index} position={coordinates}>
                <Popup>{item.details}</Popup>
              </Marker>
            )})}
          </MapContainer>
        </div>
        <div className='section1-address'>
          <p className='has-text-weight-bold section1-address-title' style={{color: content1.titleColor}}>{content1.title}</p>
          {content1.array.map((item,i) => {
            const logo = getImage(item.logo)
            return(
              <a href={item.path} target="_blank" rel="noopener noreferrer" style={{color: "black", cursor: "pointer"}}>
                <div key={i} className='section1-address-sub'>
                  <div className='address-logo-bg'>
                    {logo ? (
                    <GatsbyImage image={logo} alt='Loading...' className='address-logo'/>
                    ) : (
                    <img src={item.logo} alt='Loading...' className='address-logo'/>
                    )}
                  </div>
                  <p className= { i === 1 ? 'section1-address-sub-p' : 'section1-address-sub-p'} 
                  onClick={() => {if(item && item.coordinates && item.coordinates.latitude && item.coordinates.longitude) { return setSelectedCoordinates([item.coordinates.latitude, item.coordinates.longitude])}}}>{item.details}</p>
                </div>
              </a>
            )
          })} 
        </div>
      </div>
    </div>
  )
}

const TitleFragment = ({ fragment, color }) => (
  <span className='has-text-weight-medium has-text-weight-bold set-title' style={{ color }}>{fragment + " "}</span>
);

const Section2 = ({ content2 }) => {
  return (
    <div className='section2-main-container section2'>
      <div className='section2-container'>
        <div className='section2-container-msg-title'>
          {content2.title.map((value,i) => (
            <TitleFragment key={i} {...value}/>
          ))}
          <div className='section2-container-msg-line-con'>
            <p className='section2-container-msg-title-line'></p>
          </div>
        </div>
        <div className='section2-container-form'>
          <p>{content2.info}</p>
          <div className='section2-container-form-sub'>
            {content2.array.map((list,index) => (
              <div key={index} className='section2-container-form-sub-1'>
                <label for={list.for}>{list.title}</label>
                <input type={list.type} id={list.for} required className='input-field-1'/>
              </div>
            ))}
          </div>
            <div className='section2-container-form-sub-2'>
                <label for="message">{content2.msg}</label>
                <textarea id="message" name="message" required className='input-field-2'></textarea>
            </div>
          
            <button className='form-button' style={{background: content2.buttonColor}}>{content2.buttonText}</button>
            <p className='form-note'>Note: {content2.note}</p>
        </div>
      </div>
    </div>
  )
}

export const ContactUsTemplate = ({section1 , section2}) => {
    return (
        <div>
          <Section1 content1 = {section1}/>
          <Section2 content2 = {section2}/>
        </div>
    )
}

const ContactusPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
    return (
        <Layout>
            <ContactUsTemplate 
              section1= {frontmatter.section1.desktop}
              section2= {frontmatter.section2}/>
        </Layout>
    )
}

ContactUsTemplate.propTypes = {
  section1: PropTypes.shape({
    desktop: PropTypes.object.isRequired,
    mobile: PropTypes.object.isRequired,
  }).isRequired,
  section2: PropTypes.shape({
    title: PropTypes.arrayOf(
      PropTypes.shape({
        fragment: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
      })
    ).isRequired,
    info: PropTypes.string.isRequired,
    array: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        for: PropTypes.string.isRequired,
        pattern: PropTypes.string.isRequired,
        inputmode: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      })
    ).isRequired,
    msg: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    buttonColor: PropTypes.string.isRequired,
    note: PropTypes.string.isRequired,
  }).isRequired,
}    

ContactusPage.propTypes = {
  data: PropTypes.shape({
      markdownRemark: PropTypes.object,
  }),
};

export default ContactusPage

export const pageQuery = graphql`
  query ContactusPage {
    markdownRemark(frontmatter: { templateKey: { eq: "contact-us" } }) {
      frontmatter {
        section1 {
          desktop {
            image {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
            video
            title
            array {
              logo {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
              details
              coordinates {
                latitude
                longitude
              }
              path 
            }
            titleColor
          }
          mobile {
            image {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
            video
            title
            array {
              logo {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
              details
              coordinates {
                latitude
                longitude
              }
              path 
            }
            titleColor
          }
        }
        section2 {
          title {
            fragment
            color
          }
          info
          array {
            type
            for
            pattern
            inputmode
            title
          }
          msg
          buttonText
          buttonColor
          note
        }
      }
    }
  }
`;